<template>
  <div>
    <div class="position-relative">
      <div class="general-btn-container">
        <!-- target for button teleport -->
        <div id="button" />
      </div>
    </div>
  </div>
</template>

<script >
import { useRouter } from '@/hooks/vueRouter'; // compose hooks

const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const router = useRouter();

  const openPage = location => {
    router.push(location);
  };

  return {};
};

export default __sfc_main;
</script>

<style lang="scss">
.general-btn-container {
  display: flex;
  position: absolute;
  right: 0;
  top: -50px;
  .general-btn {
    font-size: 0.875rem;
    margin-right: 0.625rem;
    &:last-child {
      margin-right: 0;
    }
  }
  .general-add-new-btn {
    font-size: 0.875rem;
    border-radius: 0.5rem;
    margin-right: 0.625rem;
    .icon {
      margin-right: 1px !important;
    }
  }
}
</style>
