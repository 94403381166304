<template>
  <b-breadcrumb
    align="is-left"
    class="app-breadcrumb"
  >
    <b-breadcrumb-item
      v-for="(breadcrumb, index) in getCurrentRouteBreadCrumbs"
      :key="index"
      tag="router-link"
      :to="breadcrumb.link || ''"
    >
      <span v-if="!isRouteEditable || !isLastBreadCrumb(index)">
        {{ breadcrumb.label }}
      </span>
      <span 
        v-else-if="index === 0 && isRouteEditable"
        :style="[isEditable ? { paddingTop: '5px' } : {}]"
      >
        {{ breadcrumb.label }}
      </span>
      <div
        v-else-if="isLastBreadCrumb(index) && isRouteEditable"
        class="is-flex is-align-items-center"
        @click="editLabel($event, breadcrumb.label)"
      >
        <span 
          v-if="!isEditable" 
          class="has-text-black editable-breadcrumb"
        >
          {{ breadcrumbLabel ? breadcrumbLabel : breadcrumb.label }}
        </span>
        <b-icon
          icon="pencil-outline"
          class="has-text-black editable-breadcrumb-icon"
          size="is-small"
        />
        <b-input 
          v-if="isEditable"
          v-model="breadcrumbLabel"
          expanded
          value="breadcrumb.label"
          class="editable-breadcrumb-input"
          @blur="outOfFocus"
        />
      </div>
    </b-breadcrumb-item>
  </b-breadcrumb>
</template>

<script >
import { computed, ref } from '@vue/composition-api';
import { useRoute } from '@/hooks/vueRouter';
import { updateModuleService } from '@/services/application-service/moduleRequests';
import { MODULE_EDITOR_ROUTE_NAME, WORKFLOW_EDITOR_ROUTE_NAME } from '@/router/routeConstants';
import { useModuleStore } from '@/modules/builder/store/moduleStore'; // compose hooks

const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const route = useRoute();
  const moduleStore = useModuleStore();
  const getCurrentRouteBreadCrumbs = computed(() => route.meta.getBreadCrumbs ? route.meta.getBreadCrumbs() : []);
  const isRouteEditable = computed(() => [WORKFLOW_EDITOR_ROUTE_NAME, MODULE_EDITOR_ROUTE_NAME].includes(route?.name));
  const isEditable = ref(false);
  const breadcrumbLabel = ref('');

  const isLastBreadCrumb = index => index === getCurrentRouteBreadCrumbs.value?.length - 1;

  const editLabel = (event, label) => {
    if (label) {
      isEditable.value = true;
      breadcrumbLabel.value = label;
    }
  };

  const outOfFocus = async event => {
    if (breadcrumbLabel && breadcrumbLabel.value && route && route.params.appId && route.params.moduleId) {
      const moduleMame = {
        name: breadcrumbLabel.value
      };
      await updateModuleService(route.params.appId, moduleStore.moduleId, moduleMame);
      isEditable.value = false;
    }
  };

  return {
    getCurrentRouteBreadCrumbs,
    isRouteEditable,
    isEditable,
    breadcrumbLabel,
    isLastBreadCrumb,
    editLabel,
    outOfFocus
  };
};

export default __sfc_main;
</script>

<style lang="scss">
.app-breadcrumb {
  font-size: 32px;
  font-weight: 700;
  font-family: Roboto, sans-serif;
}
.breadcrumb li + li::before {
    content: ">" !important;
}
.editable-breadcrumb-input > input {
  outline: none!important;
  border: none!important;
  box-shadow: none!important;
  font-size: 20px!important;
  font-weight: bold!important;
  padding: 0!important;
  width: 1000px;
  height: 32px;
  text-decoration: underline;
}

.breadcrumb li:first-child a > span {
  padding-top: 0!important;
}

.editable-breadcrumb:hover {
  text-decoration: underline;
  cursor: text;
}

.editable-breadcrumb-icon {
  display: none !important;
}

.editable-breadcrumb:hover~.editable-breadcrumb-icon {
    display: inline-flex !important;
}

</style>