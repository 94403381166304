<template>
  <div
    :class="['module-editor-header', backgroundClass]"
  >
    <div class="is-flex is-align-items-center">
      <img 
        class="header-logo"
        src="@/assets/logo-plain.svg"
        @click="navigateToModules()"
      >
      <AppBreadCrumbs />
    </div>
    <!-- teleport target for additional right aligned content -->
    <div id="moduleEditorHeaderContentRight" />
  </div>
</template>

<script >
import { computed } from '@vue/composition-api';
import { useModuleStore } from '@/modules/builder/store/moduleStore';
import { useRouter } from '@/hooks/vueRouter';
import * as ROUTES from '@/router/routeConstants';
import AppBreadCrumbs from '@/modules/core/components/AppBreadCrumbs.vue'; // compose hooks

const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const moduleStore = useModuleStore();
  const router = useRouter();
  const selectedModuleName = computed(() => moduleStore.moduleName);
  const backgroundClass = computed(() => moduleStore.moduleDetails?.type === 5 ? 'bg-gray' : 'bg-white');

  const navigateToModules = () => {
    router.push({
      name: ROUTES.APPLICATION_ROUTE_NAME
    });
  };

  return {
    backgroundClass,
    navigateToModules
  };
};

__sfc_main.components = Object.assign({
  AppBreadCrumbs
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss" scoped>
@import '~@/style/variables.scss';

.module-editor-header {
  display: flex;
  justify-content: space-between;
  padding: 0.625rem 1rem;
  align-items: center;
  width: 100%;
  z-index: 30;
  .header-logo {
    margin-right: 0.875rem;
    height: 2rem;
    cursor: pointer;
  }
  .header-heading {
    font-size: 1.5rem;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
  }
}

.bg-gray{
  background: $grey-3;
  border-bottom: 1px solid white;
}

.bg-white{
  background: white;
  border-bottom: 1px solid $grey;
}
</style>
