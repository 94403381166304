<template>
  <div class="columns is-gapless h-full">
    <div class="column is-2 is-12-mobile is-2-tablet is-1-desktop is-1-fullhd z-5">
      <Sidebar
        :page="$route.name"
        :reduced="false"
        class="border-r border-gray"
      />
    </div>
    <div
      class="column is-10 is-12-mobile is-10-tablet is-11-desktop is-11-fullhd position-relative"
    >
      <div
        class="header-container"
      >
        <div
          v-if="$route.meta.getBreadCrumbs"
          class="page-heading"
        >
          <AppBreadCrumbs />
        </div>
      </div>
      <div class="px-5">
        <template v-if="$route.meta.headerLayout">
          <component :is="$route.meta.headerLayout" />
        </template>
        <slot />
      </div>
    </div>
  </div>
</template>

<script >
import Sidebar from '@/modules/core/components/Sidebar.vue';
import AppBreadCrumbs from '@/modules/core/components/AppBreadCrumbs.vue';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  return {};
};

__sfc_main.components = Object.assign({
  Sidebar,
  AppBreadCrumbs
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss" scoped>
.header-container {
  padding: 15px 30px;
  .page-heading {
    font-size: 32px;
    font-weight: 700;
    font-family: Roboto, sans-serif;
  }
}
</style>
