<template>
  <div class="sidebar ">
    <div>
      <div class="is-flex is-justify-content-center mb-1">
        <a href="/">
          <img
            src="@/assets/logo-plain.svg"
            class="sidebar-logo"
            alt="Nuclicore"
          >
        </a>
      </div>
      <b-sidebar
        position="static"
        open
        fullwidth
      >
        <div class="p-2">
          <b-menu class="is-custom-mobile">
            <b-menu-list>
              <b-menu-item
                :active="$route.name === ROUTES.DASHBOARD_ROUTE_NAME"
                expanded
                icon="application"
                :label="!reduced ? 'Applications' : ''"
                @click="openPage('/')"
              />
              <!--              <b-menu-item-->
              <!--                :active="$route.name === ROUTES.TEST_CENTER_ROUTE_NAME"-->
              <!--                expanded-->
              <!--                icon="test-tube"-->
              <!--                :label="!reduced ? 'Test Center' : ''"-->
              <!--                @click="openPage('/test-center')"-->
              <!--              />-->
              <b-menu-item
                icon="chart-line"
                :active="$route.name === ROUTES.MONITORING_ROUTE_NAME"
                :label="!reduced ? 'Monitoring' : ''"
                @click="openPage('/monitoring')"
              />
              <b-menu-item
                icon="playlist-check"
                :active="[ROUTES.AUDIT_LOG_LIST_ROUTE_NAME, ROUTES.AUDIT_LOG_SINGLE_ROUTE_NAME].includes($route.name)"
                :label="!reduced ? 'Audit log' : ''"
                @click="openPage('/audit-log')"
              />
              <b-menu-item
                v-if="$session.get('role') === 'admin'"
                :active="$route.name === ROUTES.USERS"
                icon="account-multiple"
                :label="!reduced ? 'Users' : ''"
                @click="openPage('/users')"
              />
              <!--              <b-menu-item-->
              <!--                :disabled="true"-->
              <!--                icon="cog"-->
              <!--                :label="!reduced ? 'Global settings' : ''"-->
              <!--                @click="openPage('/settings')"-->
              <!--              />-->
            </b-menu-list>
          </b-menu>
        </div>
      </b-sidebar>
    </div>
    <div class="is-flex is-justify-content-center is-align-items-center is-flex-direction-column">
      <b-dropdown
        aria-role="list"
        class="sidebar-dropdown popup-right mb-5"
      >
        <template #trigger>
          <b-button
            type="is-grey"
            icon-left="help-circle"
            size="is-medium"
            class="disk disk-large has-text-black"
          />
        </template>
        <b-dropdown-item
          aria-role="listitem"
          class="is-flex is-align-items-center"
          @click="goToSupportPage()"
        >
          <b-icon
            icon="face-agent"
            type="is-primary"
            custom-size="mdi-22px"
            class="mr-2"
          />
          Support
        </b-dropdown-item>
        <b-dropdown-item
          aria-role="listitem"
          class="is-flex is-align-items-center"
          @click="goToDocumentation()"
        >
          <b-icon
            icon="help-circle-outline"
            type="is-primary"
            custom-size="mdi-22px"
            class="mr-2"
          />
          Help
        </b-dropdown-item>
      </b-dropdown>
      <div>
        <b-dropdown
          aria-role="list"
          class="sidebar-dropdown popup-right"
        >
          <template #trigger>
            <b-button
              :label="userNameInitials"
              :icon-left="userNameInitials ? '' : 'account'"
              :size="userNameInitials ? '' : 'is-medium'"
              type="is-primary"
              class="disk disk-large"
            />
          </template>
          <b-dropdown-item
            custom
            aria-role="listitem"
            class="is-flex is-align-items-center border-b border-grey-3 mb-2 py-2 pr-6"
          >
            <div class="disk disk-medium has-background-primary has-text-white mr-2">
              {{ userNameInitials }}
            </div>
            {{ $session.get('email') }}
          </b-dropdown-item>
          <b-dropdown-item
            aria-role="listitem"
            class="is-flex is-align-items-center"
            @click="openProfile()"
          >
            <b-icon
              icon="pencil-outline"
              type="is-black"
              custom-size="mdi-22px"
              class="mr-2"
            />
            Edit Profile
          </b-dropdown-item>
          <b-dropdown-item
            aria-role="listitem"
            class="is-flex is-align-items-center"
            @click="logOut()"
          >
            <b-icon
              icon="logout"
              type="is-black"
              custom-size="mdi-22px"
              class="mr-2"
            />
            Log out
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <!-- edit profile modal -->
    <BaseModal
      v-model="profileModalActive"
      :has-modal-card="true"
      :trap-focus="true"
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Profile"
      aria-modal
    >
      <CardPopup
        title="Profile"
        class="w-800"
        @hide="profileModalActive = false"
      >
        <template #body>
          <b-field label="First Name">
            <b-input
              v-model="user.firstName"
              type="text"
              placeholder="e.g. John"
              required
            />
          </b-field>
          <b-field label="Last Name">
            <b-input
              v-model="user.lastName"
              type="text"
              placeholder="e.g. Doe"
              required
            />
          </b-field>
          <b-field label="Email">
            <b-input
              v-model="user.email"
              type="email"
              placeholder="e.g. john.doe@example.com"
              required
            />
          </b-field>
          <b-field label="New password">
            <b-input
              v-model="user.password"
              type="password"
              placeholder="e.g. john.doe@example.com"
              required
              password-reveal
            />
          </b-field>
        </template>
        <template #footer>
          <div class="is-flex is-justify-content-space-between w-full">
            <b-button
              class="px-6 rounded-8 btn-primary-light"
              @click="profileModalActive = false"
            >
              Cancel
            </b-button>
            <b-button
              type="is-primary"
              class="px-6 rounded-8"
              @click="updateProfile()"
            >
              Update
            </b-button>
          </div>
        </template>
      </CardPopup>
    </BaseModal>
  </div>
</template>

<script >
import { defineAsyncComponent, ref, reactive, computed } from '@vue/composition-api';
import * as ROUTES from '@/router/routeConstants';
import { useSession } from '@/hooks/vueSession';
import { useRouter } from '@/hooks/vueRouter';
import { useCookies } from '@/hooks/vueCookies';
import { fetchUserByIdService, updateUserService } from '@/services/user-service/userRequests'; //-- child components --//

const BaseModal = defineAsyncComponent(() => import('@/modules/core/components/generics/base-modal/BaseModal.vue'));
const CardPopup = defineAsyncComponent(() => import('@/modules/core/components/generics/base-modal/CardPopup.vue')); //-- component props --//

const __sfc_main = {};
__sfc_main.props = {
  reduced: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  //-- compose hooks --//
  const session = useSession();
  const cookies = useCookies();
  const router = useRouter(); //-- profile form logic --//

  const user = reactive({
    firstName: '',
    lastName: '',
    role: '',
    email: '',
    password: ''
  });

  const updateProfile = async () => {
    try {
      await updateUserService(session.get('id'), user);
      profileModalActive.value = false;
    } catch (err) {
      console.error(err);
    }
  }; //-- profile modal logic --//


  const profileModalActive = ref(false);

  const openProfile = async () => {
    try {
      const {
        data: {
          data: userData
        }
      } = await fetchUserByIdService(session.get('id'));
      user.role = userData.role;
      user.email = userData.email;
      user.firstName = userData.first_name;
      user.lastName = userData.last_name;
      profileModalActive.value = true;
    } catch (err) {
      console.error(err);
    }
  }; //-- sidebar logic start --//


  const userNameInitials = computed(() => session.get('full_name') ? session.get('full_name').split(' ').map(name => name.charAt(0).toUpperCase()).join('') : '');

  const openPage = url => {
    router.push(url);
  };

  const logOut = () => {
    session.destroy();
    cookies.remove('shared_session');
    window.location.href = '/login';
  };

  const goToDocumentation = () => {
    cookies.set('shared_session_domain', window.location.protocol + '//' + window.location.hostname, 10000, '/');
    window.location.href = 'https://documentation.nuclicore.com';
  };

  const goToSupportPage = () => {
    window.open('https://app.nuclicore.com/c0b854a4-d2a8-4b9b-a956-d6143adaf42d', '_blank');
  };

  return {
    ROUTES,
    user,
    updateProfile,
    profileModalActive,
    openProfile,
    userNameInitials,
    openPage,
    logOut,
    goToDocumentation,
    goToSupportPage
  };
};

__sfc_main.components = Object.assign({
  BaseModal,
  CardPopup
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';
@import '~@/style/utilities.scss';
.sidebar {
  height: 100vh;
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 18px;
  padding-bottom: 30px;

  .sidebar-logo {
    height: 40px;
    width: 58px;
  }

  .b-sidebar {
    .sidebar-content {
      box-shadow: none !important;
      background: transparent !important;

      .menu {

        font-size: 0.85rem;

        .menu-list {
          li {
            a {
              color: black;
              display: flex;
              flex-direction: column;
              align-items: center;
              border-radius: 8px;
              padding: 15px 0px;
              text-align: center;

              &.is-active {
                background-color: rgba($primary, 0.1);

                .icon {
                  color: $primary;
                }
              }

              .icon {
                margin-bottom: 5px;
                height: 100%;
                width: 100%;

                i {
                  font-size: 22px;
                }
              }
            }
          }
        }
      }
    }
  }

  .dropdown {
    &.popup-right {
      .dropdown-menu {
        left: 60px;
        top: auto;
        bottom: 0;
      }
    }
  }
}

.sidebar-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  // min-height: 100vh;
  .sidebar-layout {
    display: flex;
    flex-direction: row;
    min-height: 100%;
    // min-height: 100vh;
  }
}

@media screen and (min-width: 1408px) {
  .sidebar {
    width: 8.33333337%;
  }
  .b-sidebar {
    .sidebar-content{
      .menu-list{
        display: block;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .b-sidebar {
    .sidebar-content {
      &.is-mini-mobile {
        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover):not(.is-mini-delayed) {
          .menu-list {
            li {
              a {
                span:nth-child(2) {
                  display: none;
                }
              }

              ul {
                padding-left: 0;

                li {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }

          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .b-sidebar {
    .sidebar-content {
      &.is-mini {
        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover):not(.is-mini-delayed) {
          .menu-list {
            li {
              a {
                span:nth-child(2) {
                  display: none;
                }
              }

              ul {
                padding-left: 0;

                li {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }

          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.is-mini-expand {
  .menu-list a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

</style>
