import Vue from 'vue';
import VueRouter from 'vue-router';
import { pinia } from '@/plugins/pinia';
import * as ROUTES from './routeConstants';
import SidebarLayout from '@/layouts/SidebarLayout.vue';
import HeaderLayout from '@/layouts/HeaderLayout.vue';
import ApplicationNavLayout from '@/modules/builder/layouts/ApplicationNavLayout.vue';
import ModuleEditorLayout from '@/modules/builder/layouts/ModuleEditorLayout.vue';
import { useApplicationMetaStore } from '@/modules/core/store/applicationMetaStore';
import { getBreadCrumbLeaf } from '@/helpers/util';

Vue.use(VueRouter);

const applicationMetaStore = useApplicationMetaStore(pinia);

const routes = [
    {
        path: '/',
        redirect: { name: 'Dashboard' }
    },
    {
        path: '/applications',
        name: ROUTES.DASHBOARD_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "core/dashboard" */ '@/modules/core/pages/Dashboard.vue'),
        meta: {
            layout: SidebarLayout,
            layoutHeading: 'Applications',
            getBreadCrumbs: () => [
                applicationMetaStore.applicationBreadCrumbs.applications
            ]
        }
    },
    {
        path: '/application/:appId/modules',
        name: ROUTES.APPLICATION_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "builder/application-modules" */ '@/modules/builder/pages/Application.vue'),
        meta: {
            layout: SidebarLayout,
            headerLayout: ApplicationNavLayout,
            layoutHeading: 'Modules',
            getBreadCrumbs: () => [
                applicationMetaStore.moduleBreadCrumbs.list,
                applicationMetaStore.moduleBreadCrumbs.modules
            ]
        }
    },
    {
        path: '/application/:appId/databases',
        name: ROUTES.DATABASE_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "builder/application-databases" */ '@/modules/builder/pages/DatabaseList.vue'),
        meta: {
            layout: SidebarLayout,
            headerLayout: ApplicationNavLayout,
            layoutHeading: 'Database',
            getBreadCrumbs: () => [
                applicationMetaStore.databaseBreadCrumbs.list,
                applicationMetaStore.databaseBreadCrumbs.databases
            ]
        }
    },
    {
        path: '/application/:appId/database/:databaseId',
        name: ROUTES.DATABASE_TABLES_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "builder/application-tables" */ '@/modules/builder/pages/database/Table.vue'),
        meta: {
            layout: SidebarLayout,
            headerLayout: ApplicationNavLayout,
            layoutHeading: 'Database',
            getBreadCrumbs: () => [
                applicationMetaStore.databaseBreadCrumbs.list,
                applicationMetaStore.databaseBreadCrumbs.detail,
                applicationMetaStore.tableBreadCrumbs.tables
            ]
        }
    },
    {
        path: '/application/:appId/database/:databaseId/table/:tableId/data',
        name: ROUTES.DATABASE_TABLE_DATA_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "builder/application-table-data" */ '@/modules/builder/pages/database/Data.vue'),
        meta: {
            layout: SidebarLayout,
            headerLayout: ApplicationNavLayout,
            layoutHeading: 'Database',
            getBreadCrumbs: () => [
                applicationMetaStore.databaseBreadCrumbs.list,
                applicationMetaStore.databaseBreadCrumbs.detail,
                applicationMetaStore.tableBreadCrumbs.detail,
                applicationMetaStore.tableBreadCrumbs.data
            ]
        }
    },
    {
        path: '/application/:appId/database/:databaseId/table/:tableId/structure',
        name: ROUTES.DATABASE_TABLE_STRUCTURE_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "builder/application-database-structure" */ '@/modules/builder/pages/database/Structure.vue'),
        meta: {
            layout: SidebarLayout,
            headerLayout: ApplicationNavLayout,
            layoutHeading: 'Database',
            getBreadCrumbs: () => [
                applicationMetaStore.databaseBreadCrumbs.list,
                applicationMetaStore.databaseBreadCrumbs.detail,
                applicationMetaStore.tableBreadCrumbs.structureDetail,
                applicationMetaStore.tableBreadCrumbs.structure
            ]
        }
    },
    {
        path: '/test-center',
        name: ROUTES.TEST_CENTER_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "test-center/list" */ '@/modules/test-center/pages/Home.vue'),
        meta: {
            layout: SidebarLayout,
            headerLayout: HeaderLayout,
            layoutHeading: 'Test Center',
            getBreadCrumbs: () => [
                applicationMetaStore.testCenterCrumbs.testCenter
            ]
        }
    },
    {
        path: '/test-center/:testId',
        name: ROUTES.TEST_CENTER_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "test-center/test-details" */ '@/modules/test-center/pages/SingleTest.vue'),
        meta: {
            layout: SidebarLayout,
            headerLayout: HeaderLayout,
            layoutHeading: 'Test Center',
            getBreadCrumbs: () => [
                applicationMetaStore.testCenterCrumbs.testCenter,
                applicationMetaStore.testCenterCrumbs.list
            ]
        }
    },
    {
        path: '/monitoring',
        name: ROUTES.MONITORING_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "monitoring/dashboard" */ '@/modules/monitoring/pages/Dashboard.vue'),
        meta: {
            layout: SidebarLayout,
            layoutHeading: 'Monitoring',
            getBreadCrumbs: () => [
                applicationMetaStore.applicationBreadCrumbs.monitoring
            ]
        }
    },
    {
        path: '/analytics',
        name: ROUTES.ANALYTICS_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "analytics/dashboard" */ '@/modules/analytics/Dashboard.vue'),
        meta: {
            layout: SidebarLayout,
            layoutHeading: 'Analytics',
            getBreadCrumbs: () => [
                applicationMetaStore.applicationBreadCrumbs.analytics
            ]
        }
    },
    {
        path: '/application/:appId/environment-variables',
        name: ROUTES.APPLICATION_ENVIRONMENT_VARIABLES_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "builder/application-env-variables" */ '@/modules/builder/components/application/AppEnvironmentVariables.vue'),
        meta: {
            layout: SidebarLayout,
            headerLayout: ApplicationNavLayout,
            layoutHeading: 'Environment variables',
            getBreadCrumbs: () => [
                applicationMetaStore.moduleBreadCrumbs.list,
                applicationMetaStore.applicationBreadCrumbs.environmentVariables
            ]
        }
    },
    {
        path: '/application/:appId/layouts',
        name: ROUTES.APPLICATION_LAYOUTS_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "builder/application-layouts" */ '@/modules/builder/pages/Layouts.vue'),
        meta: {
            layout: SidebarLayout,
            headerLayout: ApplicationNavLayout,
            layoutHeading: 'Layouts',
            getBreadCrumbs: () => [
                applicationMetaStore.moduleBreadCrumbs.list,
                applicationMetaStore.applicationBreadCrumbs.layouts
            ]
        }
    },
    {
        path: '/application/:appId/layouts/:layoutId',
        name: ROUTES.APPLICATION_SINGLE_LAYOUT_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "builder/application-layout-details" */ '@/modules/builder/pages/SingleLayout.vue'),
        meta: {
            layout: SidebarLayout,
            headerLayout: ApplicationNavLayout,
            layoutHeading: 'Layouts',
            getBreadCrumbs: () => [
                applicationMetaStore.moduleBreadCrumbs.list,
                applicationMetaStore.applicationBreadCrumbs.layouts,
                applicationMetaStore.applicationBreadCrumbs.layout
            ]
        }
    },
    {
        path: '/application/:appId/settings',
        name: ROUTES.APPLICATION_SETTINGS_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "builder/application-settings" */ '@/modules/builder/pages/Settings.vue'),
        meta: {
            layout: SidebarLayout,
            headerLayout: ApplicationNavLayout,
            layoutHeading: 'Settings',
            getBreadCrumbs: () => [
                applicationMetaStore.moduleBreadCrumbs.list,
                applicationMetaStore.applicationBreadCrumbs.settings
            ]
        }
    },
    {
        path: '/application/:appId/module/:moduleId/edit',
        name: ROUTES.MODULE_EDITOR_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "builder/module-editor" */ '@/modules/builder/pages/Module.vue'),
        meta: {
            layout: ModuleEditorLayout,
            getBreadCrumbs: () => [
                applicationMetaStore.moduleBreadCrumbs.list,
                getBreadCrumbLeaf(applicationMetaStore.moduleBreadCrumbs.edit)
            ]
        }
    },
    {
        path: '/application/:appId/module/:moduleId/workflow/:workflowId/edit',
        name: ROUTES.WORKFLOW_EDITOR_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "builder/workflow-editor" */ '@/modules/builder/pages/Workflow.vue'),
        meta: {
            layout: ModuleEditorLayout,
            getBreadCrumbs: () => [
                applicationMetaStore.moduleBreadCrumbs.list,
                applicationMetaStore.moduleBreadCrumbs.edit,
                getBreadCrumbLeaf(applicationMetaStore.workflowBreadCrumbs.edit)
            ]
        }
    },
    {
        path: '/users',
        name: ROUTES.USERS_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "users/list" */ '@/modules/users/pages/Users.vue'),
        meta: {
            layout: SidebarLayout,
            layoutHeading: 'Users',
            getBreadCrumbs: () => [
                applicationMetaStore.userBreadCrumbs.users
            ]
        }
    },
    {
        path: '/audit-log',
        name: ROUTES.AUDIT_LOG_LIST_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "audit-log/list" */ '@/modules/audit-log/pages/LogList'),
        meta: {
            layout: SidebarLayout,
            layoutHeading: 'Audit Log',
            getBreadCrumbs: () => [
                applicationMetaStore.auditLogBreadCrumbs.auditLog
            ]
        }
    },
    {
        path: '/audit-log/:id',
        name: ROUTES.AUDIT_LOG_SINGLE_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "audit-log/log-details" */ '@/modules/audit-log/pages/SingleLog'),
        meta: {
            layout: SidebarLayout,
            layoutHeading: 'Audit Log'
        }
    },
    {
        path: '/preview/application/:appId/module/:moduleId',
        name: ROUTES.PREVIEW_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "preview" */ '@/modules/preview/pages/Preview.vue')
    },
    {
        path: '/application/:appId/module/:moduleId/versions',
        name: ROUTES.VERSION_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "builder/application-module-versions" */ '@/modules/builder/pages/Version.vue'),
        meta: {
            layout: SidebarLayout,
            layoutHeading: 'Versions',
            getBreadCrumbs: () => [
                applicationMetaStore.moduleBreadCrumbs.list,
                applicationMetaStore.moduleBreadCrumbs.edit,
                applicationMetaStore.versionBreadCrumbs.versions
            ]
        }
    },
    {
        path: '/login',
        name: ROUTES.LOGIN_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "authentication/login" */ '@/modules/authentication/pages/SignIn.vue')
    },
    {
        path: '/password-forgot',
        name: ROUTES.FORGOT_PASSWORD_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "authentication/forgot-password" */ '@/modules/authentication/pages/ForgottenPassword.vue')
    },
    {
        path: '/password-reset/:token',
        name: ROUTES.RESET_PASSWORD_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "authentication/reset-password" */ '@/modules/authentication/pages/ResetPassword.vue')
    },
    {
        path: '/login-confirm',
        name: ROUTES.LOGIN_CONFIRM_ROUTE_NAME,
        component: () => import(/* webpackChunkName: "authentication/2FA" */ '@/modules/authentication/pages/TwoFactorCode.vue')
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});


router.beforeEach(async (to, from, next) => {
    await applicationMetaStore.updateBreadCrumbsMeta(to);

    if (to.name !== ROUTES.LOGIN_ROUTE_NAME && to.name !== ROUTES.FORGOT_PASSWORD_ROUTE_NAME && to.name !== ROUTES.RESET_PASSWORD_ROUTE_NAME && to.name !== ROUTES.LOGIN_CONFIRM_ROUTE_NAME && !router.app.$session.exists()) {
        next({name: ROUTES.LOGIN_ROUTE_NAME});
    } else if ((to.name === ROUTES.LOGIN_ROUTE_NAME || to.name === ROUTES.FORGOT_PASSWORD_ROUTE_NAME || to.name === ROUTES.RESET_PASSWORD_ROUTE_NAME || to.name === ROUTES.LOGIN_CONFIRM_ROUTE_NAME) && router.app.$session.exists()) {
        next({name: ROUTES.DASHBOARD_ROUTE_NAME});
    } else {
        next();
    }

    const adminRestrictedRoutes = [
        ROUTES.USERS_ROUTE_NAME, 'Settings'
    ];
    if (adminRestrictedRoutes.includes(to.name)) {
        if (router.app.$session.get('role') !== 'admin') {
            next({name: 'Dashboard'});
        }
    }

    next();
});

export default router;
